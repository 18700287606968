import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../api/apiService';
import notifyService from '../../api/notifySerivce';
import { Box, Button, FormHelperText, FormLabel, IconButton, TextField, Typography } from '@mui/material';
import DataContext from '../../context/DataContext';
import { LoadingButton } from '@mui/lab';
import cryptoJS from '../../hooks/cryptoJS';
import ReCAPTCHA from 'react-google-recaptcha';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const Changepassword = ({ handleClosePasswordModal }) => {
    const { setPageLoading, validateEmail } = useContext(DataContext)
    // const [formData, setFormData] = useState({});
    const reCAPTCHA=process.env.REACT_APP_CAPTCHA
    const { handleSubmit, control, getValues, formState: { errors } } = useForm({});
    const [loading, setLoading] = useState(false);
    const [capVal, setCapVal] = useState(null)
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handlePasswordCheck = (value) => {
        if (getValues('newPassword') !== value) {
            return 'Conform Password is mismatch';
        }
    }
    const onSubmit = async (data) => {
        setPageLoading(true)
        setLoading(true);
        var getform = data
        getform.id=localStorage.getItem('userId')
        getform.oldPassword = cryptoJS(data.oldPassword)
        getform.newPassword = cryptoJS(data.newPassword)
        getform.confirmPassword = cryptoJS(data.confirmPassword)
        apiService(`user/password/update`, getform, 'post').then((res) => {
            setPageLoading(false)
            setLoading(false);
            if (res) {
                if (res.data.responseMessage === "Password Updated") {
                    notifyService('success', res.data.responseMessage)
                    handleClosePasswordModal()
                } else {
                    notifyService('danger', res.data.responseMessage)
                }
            }
        })
    }
    const onError = (errors, e) => {
        notifyService('danger', 'Form is invalid', 'Please check the mandatory fields')
    };

    return (
        <>
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Forgot Password
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className='row'>
                            <div className='col-lg-12 col-12'>
                                <div className='form-group mb-3'>
                                    <FormLabel shrink="true" className='input_Lable'>Old Password <span className='text-blue'></span></FormLabel>
                                    <Controller name="oldPassword" control={control} defaultValue="" rules={{ required: `Old Password is required`}}
                                        render={({ field }) =>
                                            <TextField
                                                placeholder='Enter Old Password'
                                                fullWidth
                                                autoFocus
                                                type='text'
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.oldPassword && errors.oldPassword.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-12 col-12'>
                                <div className='form-group mb-3'>
                                    <FormLabel shrink="true" className='input_Lable'>New Password <span className='text-blue'></span></FormLabel>
                                    <Controller name="newPassword" control={control} defaultValue="" rules={{ required: `New Password is required` }}
                                        render={({ field }) =>
                                            <TextField
                                                placeholder='Enter New Password'
                                                fullWidth
                                                {...field}
                                                type={showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment:
                                                        <IconButton
                                                            onClick={handleClickShowPassword}
                                                        >{showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                }}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.newPassword && errors.newPassword.message}</FormHelperText>
                                </div>
                            </div>
                            <div className='col-lg-12 col-12'>
                                <div className='form-group mb-3'>
                                    <FormLabel shrink="true" className='input_Lable'>Confirm Password <span className='text-blue'></span></FormLabel>
                                    <Controller name="confirmPassword" control={control} defaultValue="" rules={{ required: `Confirm Password is required`, validate: handlePasswordCheck }}
                                        render={({ field }) =>
                                            <TextField
                                                placeholder='Enter Confirm Password'
                                                fullWidth
                                                {...field}
                                                type='password'
                                            // InputProps={{
                                            //     endAdornment:
                                            //         <IconButton
                                            //             onClick={handleClickShowConfirmPassword}
                                            //         >{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            //         </IconButton>
                                            // }}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.confirmPassword && errors.confirmPassword.message}</FormHelperText>
                                </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <div className='d-flex justify-content-center mb-3'>
                                <ReCAPTCHA sitekey={reCAPTCHA} onChange={val => setCapVal(val)} />
                            </div>
                            <LoadingButton
                                loadingPosition="start"
                                type="submit"
                                loading={loading}
                                disabled={!capVal}
                                className="b-yellow-btn"
                            >
                                <span>Update</span>
                            </LoadingButton>
                            <Button className='blue-btn ml-3' onClick={handleClosePasswordModal}>Cancel</Button>
                        </div>
                    </form>
                </Typography>
            </Box>


        </>
    )
}

export default Changepassword