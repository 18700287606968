import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  FormLabel,
  Grid,
  Tab,
  TextField,
} from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
import React, { useContext, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DataContext from "../../../context/DataContext";
import { Controller, useForm } from "react-hook-form";
import notifyService from "../../../api/notifySerivce";
import apiService from "../../../api/apiService";
// import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import AddIcon from "@mui/icons-material/Add";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// import Challengelist from './Challengelist';/
// import Solutionlist from '../Solution-page/Solutionlist';
import { Helmet } from "react-helmet";
import Challengelist from "../live-challenges-page/challenges-page/Challengelist";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { useParams } from "react-router-dom";
import Homepreviouslist from "./Homepreviouslist";
import RequirementList from "../post-requirement-page/RequirementList";
import SearchInput from "../../common-pages/SearchInput";
// import Jurylist from '../jury-page/Jurylist';
// import Jurylayout from '../jury-page/Jurylayout';

const Homechallengelist = () => {
  const { navigator, setPageLoading } = useContext(DataContext);
  const { name } = useParams();
  let path;
  if (name !== undefined) {
    path = name;
  }
  // const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({});
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  let roleId = localStorage.getItem("roleId");
  // let roleName = localStorage.getItem('role')
  // const [challengeCategory, setChallengeCategory] = useState(0)
  const [title, setTitle] = useState("");
  useDidMountEffect(() => {
    dropdownValueApi();
  }, []);
  useDidMountEffect(() => {
    // dropdownValueApi();
    // challengesListApi();
    challengeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, name, title]);
  const [sectorDataList, setsectorDataList] = useState([]);
  const [technologyAreasDataList, setTechnologyAreasDataList] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(
      `admin/dropdownvalues/get?key=dropdownall`,
      "",
      "unauthget"
    ).then((res) => {
      if (res) {
        if (res.data) {
          var val = res.data.dropDownValues;
          setsectorDataList(val.sectors);
        }
      }
    });
  };
  var clickval = localStorage.getItem("val");
  const [challengesDataList, setChallengesDataList] = useState([]);
  // let role = localStorage.getItem('role')
  const challengesListApi = () => {
    setChallengesDataList([]);
    let url;
    if (path === "government") {
      url = `challenge/title/list?entityType=${""}?challengeCategory=1`;
    } else {
      url = `challenge/title/list?entityType=${""}`;
    }
    apiService(url, "", "unauthget").then((res) => {
      if (res) {
        if (res.data) {
          setChallengesDataList(res.data);
        }
      }
    });
  };
  const [showSector, setShowSector] = useState(true);
  const [showChallenges, setShowChallenges] = useState(true);
  const handleSelectChallenges = (value) => {
    setValue("challengeTitle", value);
    setValue("sector", "");
    setValue("technologyArea", "");
    setShowSector(false);
    setShowChallenges(true);
    challengeList();
  };
  const handleSelectSector = (value) => {
    setValue("sector", value);
    setValue("technologyArea", "");
    const name = sectorDataList.filter(
      (item) => item.sector.includes(value) || item.sector.includes(value)
    );
    setTechnologyAreasDataList(name[0].technologyAreas);
    setShowSector(true);
    setShowChallenges(false);
    challengeList();
  };
  const handleSelecttechnologyArea = (value) => {
    setValue("technologyArea", value);
    challengeList();
  };
  const resetFiliter = () => {
    setValue("sector", "");
    setValue("technologyArea", "");
    setValue("challengeTitle", "");
    setShowSector(true);
    setShowChallenges(true);
    setTechnologyAreasDataList([]);
    challengeList();
  };
  const [challengeListValue, setChallengeListValue] = useState([]);
  const challengeList = () => {
    setPageLoading(true);
    setChallengeListValue([]);
    let challengeCategoryVal;
    if (path === "corporate") {
      challengeCategoryVal = 0;
    } else if (path === "government") {
      challengeCategoryVal = 1;
    } else if (path === "msme") {
      challengeCategoryVal = 2;
    } else if (path === "enablers") {
      challengeCategoryVal = 3;
    }
    var req = {
      listSize: 12,
      pageNumber: page,
      challengeTitle: title,
      sector: getValues("sector"),
      technologyArea: getValues("technologyArea"),
      live: 2,
      challengeType: 1,
      // challengeCategory: path === "government" ? 1 : 0,
      challengeCategory: challengeCategoryVal,
    };
    apiService("challenge/home/list", req, "unauthpost").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data) {
          if (res.data.responseModelList) {
            setChallengeListValue(res.data.responseModelList);
            setTotalPage(res.data.totalPages);
          }
        }
      }
    });
  };
  const handleViewDetails = (id, userid) => {
    // if (roleId === null) {
    //   navigator("/sign-in");
    //   localStorage.setItem("click", "live");
    // } else {
    //   navigator(`${name}/challenges/details/${btoa(id)}`);
    // }
    navigator(`${name}/challenges/details/${btoa(id)}/${btoa(userid)}`);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlePostChallenge = () => {
    if (roleId === null) {
      navigator("/sign-in");
      localStorage.setItem("click", "post");
    } else {
      navigator(`${name}/post-challenges`);
    }
  };
  const handleType = (value) => {
    challengeList();
  };
  const handleChallangeType = (value) => {
    challengeList();
  };
  const [tabValue, setTabValue] = React.useState("2");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setTabChallangeValue("1");
  };
  const [tabChallangeValue, setTabChallangeValue] = React.useState("1");
  const handleChallangeTabChange = (event, newValue) => {
    setTabChallangeValue(newValue);
    setTabValue("2");
  };
  return (
    <div className="p-4">
      <>
        {path === "corporate" && (
          // <h6 className='mb-4'>
          //     Corporates interested in utilising external expertise to accelerate innovation can post their problem statements in the portal to source solutions from startups and innovators.
          // </h6>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="mb-4"
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ margin: "0px auto", textAlign: "center" }}
            >
              <img
                src="/images/corporate-img.jpg"
                className="cor-govt img-fluid"
                alt=""
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p className="cor-govt-txt">
                <h2>Corporates Challenge</h2>
                Corporates interested in utilising external expertise to
                accelerate innovation can post their problem statements in the
                portal to source solutions from startups and innovators.
              </p>
            </Grid>
          </Grid>
        )}
        {path === "government" && (
          // <h6 className='mb-4'>
          //     Government agencies can post their problem statements in the portal to make use of innovative solutions for their public service delivery from startups and innovators.
          // </h6>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="mb-4"
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ margin: "0px auto", textAlign: "center" }}
            >
              <img
                src="/images/government-img.jpg"
                className="cor-govt img-fluid"
                alt=""
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {tabValue === "1" ? (
                <>
                  <h2>Government Challenge</h2>
                  <p className="cor-govt-txt">
                    Government Departments / Agencies can post their problem
                    statements in the portal to make use of innovative solutions
                    for their public service delivery from startups and
                    innovators.
                  </p>
                </>
              ) : (
                <>
                  <h2>Government Requirements</h2>
                  <p className="cor-govt-txt">
                    Government Departments / Agencies can post their
                    requirements in the portal to make use of innovative
                    solutions for their public service delivery from startups
                    and innovators.
                  </p>
                </>
              )}
            </Grid>
          </Grid>
        )}
        {path === "msme" && (
          // <h6 className='mb-4'>
          //     Corporates interested in utilising external expertise to accelerate innovation can post their problem statements in the portal to source solutions from startups and innovators.
          // </h6>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="mb-4"
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ margin: "0px auto", textAlign: "center" }}
            >
              <img
                src="/images/hand.jpg"
                className="cor-govt img-fluid"
                alt=""
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p className="cor-govt-txt">
                <h2>MSME's Challenge</h2>
                MSME's interested in utilising external expertise to accelerate
                innovation can post their problem statements in the portal to
                source solutions from startups and innovators.
              </p>
            </Grid>
          </Grid>
        )}
        {path === "enablers" && (
          // <h6 className='mb-4'>
          //     Corporates interested in utilising external expertise to accelerate innovation can post their problem statements in the portal to source solutions from startups and innovators.
          // </h6>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="mb-4"
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ margin: "0px auto", textAlign: "center" }}
            >
              <img
                src="/images/corporate-img.jpg"
                className="cor-govt img-fluid"
                alt=""
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <p className="cor-govt-txt">
                <h2>Other Enablers Challenge</h2>
                Other Enablers interested in utilising external expertise to
                accelerate innovation can post their problem statements in the
                portal to source solutions from startups and innovators.
              </p>
            </Grid>
          </Grid>
        )}
      </>
      {clickval === "Corporates" && (
        <>
          <TabContext sx={{ padding: "25px" }} value={tabChallangeValue}>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
              }}
            >
              <TabList
                value={tabChallangeValue}
                onChange={handleChallangeTabChange}
                variant="scrollable"
                // scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
              >
                <Tab
                  className="tab_lable_name"
                  label="Live Challenges"
                  value="1"
                  onClick={() => handleChallangeType(1)}
                />
                <Tab
                  className="tab_lable_name"
                  label="Past Challenges"
                  value="2"
                  onClick={() => handleChallangeType(0)}
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "15px 0px" }}>
              {/* <h5>
                    <span className="text-uppercase"></span> LIVE CHALLENGES
                  </h5> */}
              <div className="d-flex w-100">
                <SearchInput setValue={setTitle} lable={"Challenge Title"} />
                <div className="ml-auto">
                  <Button
                    className="c-blue"
                    startIcon={<FilterAltOutlinedIcon />}
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Filter
                  </Button>
                  <Button
                    startIcon={<AddIcon />}
                    className="yellow-btn-sm px-3 ml-3"
                    onClick={() => handlePostChallenge()}
                  >
                    Post Your Challenge
                  </Button>
                </div>
              </div>
              <div className="filter_select my-3 collapse" id="collapseExample">
                <form className="signin-form">
                  <div className="row justify-content-center">
                    {showSector === true && (
                      <>
                        <div className="col-lg-4 col-md-4 col-12">
                          <div className="form-group mb-3 text-center">
                            <FormLabel shrink="true" className="input_Lable">
                              Sector{" "}
                            </FormLabel>
                            <Controller
                              name="sector"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <Autocomplete
                                  disableClearable
                                  disablePortal
                                  {...field}
                                  sx={{ background: "white" }}
                                  ListboxProps={{
                                    style: { textAlign: "left" },
                                  }}
                                  options={sectorDataList.map(
                                    (option) => option.sector
                                  )}
                                  onChange={(e, selectedOptions) =>
                                    handleSelectSector(selectedOptions)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Filter by Sector"
                                    />
                                  )}
                                />
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.sector && errors.sector.message}
                            </FormHelperText>
                          </div>
                        </div>
                        {getValues("sector") !== "All" &&
                          getValues("sector") !== "Others" &&
                          getValues("sector") !== "" && (
                            <div className="col-lg-4 col-md-4 col-12">
                              <div className="form-group mb-3 text-center">
                                <FormLabel
                                  shrink="true"
                                  className="input_Lable"
                                >
                                  Technology Area{" "}
                                </FormLabel>
                                <Controller
                                  name="technologyArea"
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: false }}
                                  render={({ field }) => (
                                    <Autocomplete
                                      disableClearable
                                      disablePortal
                                      {...field}
                                      sx={{ background: "white" }}
                                      ListboxProps={{
                                        style: { textAlign: "left" },
                                      }}
                                      options={technologyAreasDataList}
                                      onChange={(e, selectedOptions) =>
                                        handleSelecttechnologyArea(
                                          selectedOptions
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Filter by Technology Area"
                                        />
                                      )}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.technologyArea &&
                                    errors.technologyArea.message}
                                </FormHelperText>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                    {(getValues("challengeTitle") !== "" ||
                      getValues("sector") !== "") && (
                      <div className="col-12 text-center">
                        <Button
                          onClick={resetFiliter}
                          variant="outlined"
                          color="error"
                        >
                          Reset
                        </Button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <Challengelist
                challengeListValue={challengeListValue}
                page={page}
                totalPage={totalPage}
                handleViewDetails={handleViewDetails}
                handleChange={handleChange}
                viewShow={false}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "15px 0px" }}>
              <Homepreviouslist path={path} />
            </TabPanel>
          </TabContext>
        </>
      )}
      {clickval === "Government" && (
        <div className="grybg">
          <TabContext sx={{ padding: "25px" }} value={tabValue}>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
              }}
            >
              <div className="d-flex justify-content-center">
                <TabList
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="scrollable"
                  aria-label="visible arrows tabs example"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { opacity: 0.3 },
                    },
                  }}
                >
                  {/* <Tab
                        className="tab_lable_name"
                        label="Requirements"
                        value="1"
                        onClick={() => handleType(0)}
                      />
                      <Tab
                        className="tab_lable_name"
                        label="Challenges"
                        value="2"
                        onClick={() => handleType(1)}
                      /> */}
                </TabList>
              </div>
            </Box>
            {/* <TabPanel value="1" sx={{ padding: "15px 0px" }}>
                  <RequirementList />
                </TabPanel>
                <TabPanel value="2" sx={{ padding: "15px 0px" }}>
                  <TabContext sx={{ padding: "25px" }} value={tabChallangeValue}>
                    <Box
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                      }}
                    >
                      <TabList
                        value={tabChallangeValue}
                        onChange={handleChallangeTabChange}
                        variant="scrollable"
                        aria-label="visible arrows tabs example"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            "&.Mui-disabled": { opacity: 0.3 },
                          },
                        }}
                      >
                        <Tab
                          className="tab_lable_name"
                          label="Live Challenges"
                          value="1"
                          onClick={() => handleChallangeType(1)}
                        />
                        <Tab
                          className="tab_lable_name"
                          label="Past Challenges"
                          value="2"
                          onClick={() => handleChallangeType(0)}
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: "15px 0px" }}>
                     
                      <div className="d-flex w-100">
                        <SearchInput setValue={setTitle} lable={'Challenge Title'} />

                        <div className="ml-auto">
                          <Button
                            className="c-blue"
                            startIcon={<FilterAltOutlinedIcon />}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseExample"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            Filter
                          </Button>
                          <Button
                            startIcon={<AddIcon />}
                            className="yellow-btn-sm px-3 ml-3"
                            onClick={() => handlePostChallenge()}
                          >
                            Post Your Challenge
                          </Button>
                        </div>
                      </div>
                      <div
                        className="filter_select my-3 collapse"
                        id="collapseExample"
                      >
                        <form className="signin-form">
                          <div className="row justify-content-center">
                            {showSector === true && (
                              <>
                                <div className="col-lg-4 col-md-4 col-12">
                                  <div className="form-group mb-3 text-center">
                                    <FormLabel shrink="true" className="input_Lable">
                                      Sector{" "}
                                    </FormLabel>
                                    <Controller
                                      name="sector"
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: false }}
                                      render={({ field }) => (
                                        <Autocomplete
                                          disableClearable
                                          disablePortal
                                          {...field}
                                          sx={{ background: "white" }}
                                          options={sectorDataList.map(
                                            (option) => option.sector
                                          )}
                                          onChange={(e, selectedOptions) =>
                                            handleSelectSector(selectedOptions)
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Filter by Sector"
                                            />
                                          )}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.sector && errors.sector.message}
                                    </FormHelperText>
                                  </div>
                                </div>
                                {(getValues("sector") !== "All" &&
                                  getValues("sector") !== "Others" && getValues("sector") !== "") && (
                                    <div className="col-lg-4 col-md-4 col-12">
                                      <div className="form-group mb-3 text-center">
                                        <FormLabel
                                          shrink="true"
                                          className="input_Lable"
                                        >
                                          Technology Area{" "}
                                        </FormLabel>
                                        <Controller
                                          name="technologyArea"
                                          control={control}
                                          defaultValue=""
                                          rules={{ required: false }}
                                          render={({ field }) => (
                                            <Autocomplete
                                              disableClearable
                                              disablePortal
                                              {...field}
                                              sx={{ background: "white" }}
                                              options={technologyAreasDataList}
                                              onChange={(e, selectedOptions) =>
                                                handleSelecttechnologyArea(
                                                  selectedOptions
                                                )
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder="Filter by Technology Area"
                                                />
                                              )}
                                            />
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.technologyArea &&
                                            errors.technologyArea.message}
                                        </FormHelperText>
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                            {(getValues("sector") !== "") && (
                              <div className="col-12 text-center">
                                <Button
                                  onClick={resetFiliter}
                                  variant="outlined"
                                  color="error"
                                >
                                  Reset
                                </Button>
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                      <Challengelist
                        challengeListValue={challengeListValue}
                        page={page}
                        totalPage={totalPage}
                        handleViewDetails={handleViewDetails}
                        handleChange={handleChange}
                        viewShow={false}
                      />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "15px 0px" }}>
                      <Homepreviouslist path={path} />
                    </TabPanel>
                  </TabContext>
                </TabPanel> */}
          </TabContext>
          <h3 className="challenges-title">Requirements</h3>
          <RequirementList />
          <h3 className="challenges-title mb-2">Challenges</h3>
          <TabContext sx={{ padding: "25px" }} value={tabChallangeValue}>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
              }}
            >
              <TabList
                value={tabChallangeValue}
                onChange={handleChallangeTabChange}
                variant="scrollable"
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
              >
                <Tab
                  className="tab_lable_name"
                  label="Live Challenges"
                  value="1"
                  onClick={() => handleChallangeType(1)}
                />
                <Tab
                  className="tab_lable_name"
                  label="Past Challenges"
                  value="2"
                  onClick={() => handleChallangeType(0)}
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "15px 0px" }}>
              <div className="d-flex w-100">
                <SearchInput setValue={setTitle} lable={"Challenge Title"} />

                <div className="ml-auto">
                  <Button
                    className="c-blue"
                    startIcon={<FilterAltOutlinedIcon />}
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Filter
                  </Button>
                  <Button
                    startIcon={<AddIcon />}
                    className="yellow-btn-sm px-3 ml-3"
                    onClick={() => handlePostChallenge()}
                  >
                    Post Your Challenge
                  </Button>
                </div>
              </div>
              <div className="filter_select my-3 collapse" id="collapseExample">
                <form className="signin-form">
                  <div className="row justify-content-center">
                    {showSector === true && (
                      <>
                        <div className="col-lg-4 col-md-4 col-12">
                          <div className="form-group mb-3 text-center">
                            <FormLabel shrink="true" className="input_Lable">
                              Sector{" "}
                            </FormLabel>
                            <Controller
                              name="sector"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <Autocomplete
                                  disableClearable
                                  disablePortal
                                  {...field}
                                  sx={{ background: "white" }}
                                  ListboxProps={{
                                    style: { textAlign: "left" },
                                  }}
                                  options={sectorDataList.map(
                                    (option) => option.sector
                                  )}
                                  onChange={(e, selectedOptions) =>
                                    handleSelectSector(selectedOptions)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Filter by Sector"
                                    />
                                  )}
                                />
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.sector && errors.sector.message}
                            </FormHelperText>
                          </div>
                        </div>
                        {getValues("sector") !== "All" &&
                          getValues("sector") !== "Others" &&
                          getValues("sector") !== "" && (
                            <div className="col-lg-4 col-md-4 col-12">
                              <div className="form-group mb-3 text-center">
                                <FormLabel
                                  shrink="true"
                                  className="input_Lable"
                                >
                                  Technology Area{" "}
                                </FormLabel>
                                <Controller
                                  name="technologyArea"
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: false }}
                                  render={({ field }) => (
                                    <Autocomplete
                                      disableClearable
                                      disablePortal
                                      {...field}
                                      sx={{ background: "white" }}
                                      ListboxProps={{
                                        style: { textAlign: "left" },
                                      }}
                                      options={technologyAreasDataList}
                                      onChange={(e, selectedOptions) =>
                                        handleSelecttechnologyArea(
                                          selectedOptions
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Filter by Technology Area"
                                        />
                                      )}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.technologyArea &&
                                    errors.technologyArea.message}
                                </FormHelperText>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                    {getValues("sector") !== "" && (
                      <div className="col-12 text-center">
                        <Button
                          onClick={resetFiliter}
                          variant="outlined"
                          color="error"
                        >
                          Reset
                        </Button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <Challengelist
                challengeListValue={challengeListValue}
                page={page}
                totalPage={totalPage}
                handleViewDetails={handleViewDetails}
                handleChange={handleChange}
                viewShow={false}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "15px 0px" }}>
              <Homepreviouslist path={path} />
            </TabPanel>
          </TabContext>
        </div>
      )}
      {clickval === "MSME's" && (
        <>
          <TabContext sx={{ padding: "25px" }} value={tabChallangeValue}>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
              }}
            >
              <TabList
                value={tabChallangeValue}
                onChange={handleChallangeTabChange}
                variant="scrollable"
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
              >
                <Tab
                  className="tab_lable_name"
                  label="Live Challenges"
                  value="1"
                  onClick={() => handleChallangeType(1)}
                />
                <Tab
                  className="tab_lable_name"
                  label="Past Challenges"
                  value="2"
                  onClick={() => handleChallangeType(0)}
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "15px 0px" }}>
              <div className="d-flex w-100">
                <SearchInput setValue={setTitle} lable={"Challenge Title"} />
                <div className="ml-auto">
                  <Button
                    className="c-blue"
                    startIcon={<FilterAltOutlinedIcon />}
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Filter
                  </Button>
                  <Button
                    startIcon={<AddIcon />}
                    className="yellow-btn-sm px-3 ml-3"
                    onClick={() => handlePostChallenge()}
                  >
                    Post Your Challenge
                  </Button>
                </div>
              </div>
              <div className="filter_select my-3 collapse" id="collapseExample">
                <form className="signin-form">
                  <div className="row justify-content-center">
                    {showSector === true && (
                      <>
                        <div className="col-lg-4 col-md-4 col-12">
                          <div className="form-group mb-3 text-center">
                            <FormLabel shrink="true" className="input_Lable">
                              Sector{" "}
                            </FormLabel>
                            <Controller
                              name="sector"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <Autocomplete
                                  disableClearable
                                  disablePortal
                                  {...field}
                                  sx={{ background: "white" }}
                                  ListboxProps={{
                                    style: { textAlign: "left" },
                                  }}
                                  options={sectorDataList.map(
                                    (option) => option.sector
                                  )}
                                  onChange={(e, selectedOptions) =>
                                    handleSelectSector(selectedOptions)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Filter by Sector"
                                    />
                                  )}
                                />
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.sector && errors.sector.message}
                            </FormHelperText>
                          </div>
                        </div>
                        {getValues("sector") !== "All" &&
                          getValues("sector") !== "Others" &&
                          getValues("sector") !== "" && (
                            <div className="col-lg-4 col-md-4 col-12">
                              <div className="form-group mb-3 text-center">
                                <FormLabel
                                  shrink="true"
                                  className="input_Lable"
                                >
                                  Technology Area{" "}
                                </FormLabel>
                                <Controller
                                  name="technologyArea"
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: false }}
                                  render={({ field }) => (
                                    <Autocomplete
                                      disableClearable
                                      disablePortal
                                      {...field}
                                      sx={{ background: "white" }}
                                      ListboxProps={{
                                        style: { textAlign: "left" },
                                      }}
                                      options={technologyAreasDataList}
                                      onChange={(e, selectedOptions) =>
                                        handleSelecttechnologyArea(
                                          selectedOptions
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Filter by Technology Area"
                                        />
                                      )}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.technologyArea &&
                                    errors.technologyArea.message}
                                </FormHelperText>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                    {(getValues("challengeTitle") !== "" ||
                      getValues("sector") !== "") && (
                      <div className="col-12 text-center">
                        <Button
                          onClick={resetFiliter}
                          variant="outlined"
                          color="error"
                        >
                          Reset
                        </Button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <Challengelist
                challengeListValue={challengeListValue}
                page={page}
                totalPage={totalPage}
                handleViewDetails={handleViewDetails}
                handleChange={handleChange}
                viewShow={false}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "15px 0px" }}>
              <Homepreviouslist path={path} />
            </TabPanel>
          </TabContext>
        </>
      )}
      {clickval === "Other Enablers" && (
        <>
          <TabContext sx={{ padding: "25px" }} value={tabChallangeValue}>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
              }}
            >
              <TabList
                value={tabChallangeValue}
                onChange={handleChallangeTabChange}
                variant="scrollable"
                // scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
              >
                <Tab
                  className="tab_lable_name"
                  label="Live Challenges"
                  value="1"
                  onClick={() => handleChallangeType(1)}
                />
                <Tab
                  className="tab_lable_name"
                  label="Past Challenges"
                  value="2"
                  onClick={() => handleChallangeType(0)}
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "15px 0px" }}>
              {/* <h5>
                    <span className="text-uppercase"></span> LIVE CHALLENGES
                  </h5> */}
              <div className="d-flex w-100">
                <SearchInput setValue={setTitle} lable={"Challenge Title"} />
                <div className="ml-auto">
                  <Button
                    className="c-blue"
                    startIcon={<FilterAltOutlinedIcon />}
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Filter
                  </Button>
                  <Button
                    startIcon={<AddIcon />}
                    className="yellow-btn-sm px-3 ml-3"
                    onClick={() => handlePostChallenge()}
                  >
                    Post Your Challenge
                  </Button>
                </div>
              </div>
              <div className="filter_select my-3 collapse" id="collapseExample">
                <form className="signin-form">
                  <div className="row justify-content-center">
                    {showSector === true && (
                      <>
                        <div className="col-lg-4 col-md-4 col-12">
                          <div className="form-group mb-3 text-center">
                            <FormLabel shrink="true" className="input_Lable">
                              Sector{" "}
                            </FormLabel>
                            <Controller
                              name="sector"
                              control={control}
                              defaultValue=""
                              rules={{ required: false }}
                              render={({ field }) => (
                                <Autocomplete
                                  disableClearable
                                  disablePortal
                                  {...field}
                                  sx={{ background: "white" }}
                                  ListboxProps={{
                                    style: { textAlign: "left" },
                                  }}
                                  options={sectorDataList.map(
                                    (option) => option.sector
                                  )}
                                  onChange={(e, selectedOptions) =>
                                    handleSelectSector(selectedOptions)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Filter by Sector"
                                    />
                                  )}
                                />
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.sector && errors.sector.message}
                            </FormHelperText>
                          </div>
                        </div>
                        {getValues("sector") !== "All" &&
                          getValues("sector") !== "Others" &&
                          getValues("sector") !== "" && (
                            <div className="col-lg-4 col-md-4 col-12">
                              <div className="form-group mb-3 text-center">
                                <FormLabel
                                  shrink="true"
                                  className="input_Lable"
                                >
                                  Technology Area{" "}
                                </FormLabel>
                                <Controller
                                  name="technologyArea"
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: false }}
                                  render={({ field }) => (
                                    <Autocomplete
                                      disableClearable
                                      disablePortal
                                      {...field}
                                      sx={{ background: "white" }}
                                      ListboxProps={{
                                        style: { textAlign: "left" },
                                      }}
                                      options={technologyAreasDataList}
                                      onChange={(e, selectedOptions) =>
                                        handleSelecttechnologyArea(
                                          selectedOptions
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Filter by Technology Area"
                                        />
                                      )}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.technologyArea &&
                                    errors.technologyArea.message}
                                </FormHelperText>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                    {(getValues("challengeTitle") !== "" ||
                      getValues("sector") !== "") && (
                      <div className="col-12 text-center">
                        <Button
                          onClick={resetFiliter}
                          variant="outlined"
                          color="error"
                        >
                          Reset
                        </Button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <Challengelist
                challengeListValue={challengeListValue}
                page={page}
                totalPage={totalPage}
                handleViewDetails={handleViewDetails}
                handleChange={handleChange}
                viewShow={false}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "15px 0px" }}>
              <Homepreviouslist path={path} />
            </TabPanel>
          </TabContext>
        </>
      )}
    </div>
  );
};

export default Homechallengelist;
