import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import apiService from "../../api/apiService";
import notifyService from "../../api/notifySerivce";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import DataContext from "../../context/DataContext";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import cryptoJS from "../../hooks/cryptoJS";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import ReCAPTCHA from "react-google-recaptcha";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Helmet } from "react-helmet";
import DoneAllIcon from "@mui/icons-material/DoneAll";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "#2B377A",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const Signuppage = () => {
  const { navigator, setPageLoading, validatePhoneNumber, validateEmail } =
    useContext(DataContext);
  const reCAPTCHA = process.env.REACT_APP_CAPTCHA;
  // const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({});
  const [loading, setLoading] = useState(false);
  const [capVal, setCapVal] = useState(null);
  let checkMenu = localStorage.getItem("click");
  localStorage.getItem("roleName");
  console.log(localStorage);
  useDidMountEffect(() => {
    // setValue('email', 'subash.k@amizhth.com')
    // handleOpen()
    if (checkMenu === "post") {
      // setValue('roleId', 3)
      clearErrors("roleId");
      setValue("entityType", "");
    }
  }, []);

  const handleRoleId = (value) => {
    setValue("roleId", value);
    clearErrors("roleId");
    setValue("entityType", "");
  };
  const entityTypeData = [
    "Government Departments",
    "Corporate",
    "Academic and R&D Institutions",
    "Others",
  ];
  const rollTypeData = [
    {
      rollId: 3,
      type: "Corporate",
    },
    {
      rollId: 5,
      type: "Government",
    },
    {
      rollId: 7,
      type: "Researcher",
    },
    {
      rollId: 4,
      type: "Solution Provider",
    },
    {
      rollId: 10,
      type: "MSMEs",
    },
    {
      rollId: 11,
      type: "Other Enablers",
    },

    // 'Corporate', 'Government', 'Researcher', 'Solution Provider','MSMEs','Other Enablers'
  ];
  const handleSelectEntityType = (value) => {
    setValue("entityType", value);
    clearErrors("entityType");
  };
  const [disable, setDisable] = useState(null);
  const handleRollType = (value) => {
    setValue("roleId", value);
    clearErrors("roleId");
    setDisable(true);
  };
  const onSubmit = async (data) => {
    setPageLoading(true);
    setLoading(true);
    var getform = data;
    const roll = rollTypeData.filter((val) => val.type === data.roleId);
    data.roleId = roll[0].rollId.toString();
    // getform.roleId = localStorage.getItem('roleName')
    getform.phone = cryptoJS(data.phone);
    getform.email = cryptoJS(data.email);

    apiService(`register`, getform, "unauthpost").then((res) => {
      setPageLoading(false);
      setLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", res.data.responseMessage);
          localStorage.removeItem("roleName");
          handleOpen();
        }
      }
    });
  };
  const onError = (errors, e) => {
    notifyService(
      "danger",
      "Form is invalid",
      "Please check the mandatory fields"
    );
  };
  const handleHome = () => {
    navigator("/home");
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    navigator("/sign-in");
  };
  // const handleRoleId = (id)=>{
  //   setValue('roleId', id)
  // }
  return (
    <div className="log_section">
      <Helmet>
        <title>Open Innovation Portal | Signup</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-6 p-0">
            <div className="signup_section">
              <div className="login_header">
                <img
                  src="/images/blue-logo.png"
                  alt=""
                  onClick={handleHome}
                  className="img-fluid login-logo"
                />
                <div className="login_title">
                  <h4>Create your Account</h4>
                  <p>
                    {checkMenu === "post" ? (
                      <span>
                        Sign up and Post your Challenges{" "}
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography>As a challenge provider</Typography>
                              <b>
                                {"post your challenges using this account."}
                              </b>
                            </React.Fragment>
                          }
                        >
                          <HelpOutlineIcon className="c-pointer" />
                        </HtmlTooltip>
                      </span>
                    ) : (
                      <span>
                        Sign up and submit your application for live challenges.{" "}
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography>As a Innovator,</Typography>
                              <b>
                                {
                                  "view list of live challenges and submit your pitchdeck."
                                }
                              </b>
                            </React.Fragment>
                          }
                        >
                          <HelpOutlineIcon className="c-pointer" />
                        </HtmlTooltip>
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <div>
                <form
                  className="signin-form"
                  onSubmit={handleSubmit(onSubmit, onError)}
                >
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <FormLabel shrink="true" className="input_Lable">
                        Describe You <span className="text-blue"></span>
                      </FormLabel>
                      {/* <div className='d-flex justify-content-around'>
                        <div className={getValues('roleId') === 3 ? 'tab_signup act_signup' : 'tab_signup'} onClick={() => handleRoleId(3)}>
                          {
                            getValues('roleId') === 3 && (<DoneAllIcon />)
                          }
                          Corporate
                        </div>
                        <div className={getValues('roleId') === 5 ? 'tab_signup act_signup' : 'tab_signup'} onClick={() => handleRoleId(5)}>
                          {
                            getValues('roleId') === 5 && (<DoneAllIcon />)
                          } Government
                        </div>
                        <div className={getValues('roleId') === 7 ? 'tab_signup act_signup' : 'tab_signup'} onClick={() => handleRoleId(7)}>
                          {
                            getValues('roleId') === 7 && (<DoneAllIcon />)
                          } Researcher
                        </div>
                        <div className={getValues('roleId') === 4 ? 'tab_signup act_signup' : 'tab_signup'} onClick={() => handleRoleId(4)}>
                          {
                            getValues('roleId') === 4 && (<DoneAllIcon />)
                          } Solution Provider
                        </div>
                      </div> */}
                      <div className="form-group mb-3">
                        <Controller
                          name="roleId"
                          control={control}
                          defaultValue=""
                          rules={{ required: `Role Type is required` }}
                          render={({ field }) => (
                            <Autocomplete
                              disableClearable
                              disablePortal
                              {...field}
                              ListboxProps={{
                                style: { textAlign: "left" },
                              }}
                              options={rollTypeData.map(
                                (option) => option.type
                              )}
                              onChange={(e, selectedOptions) =>
                                handleRollType(selectedOptions)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Role Type"
                                />
                              )}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.roleId && errors.roleId.message}
                        </FormHelperText>
                      </div>

                      {/* <div className='d-none'>
                        <div className="form-group mb-3">
                          <Controller name="roleId" control={control} defaultValue="" rules={{ required: 'Role is required' }}
                            render={({ field }) =>
                              <TextField
                                {...field}
                              />} />
                        </div>
                      </div>
                      <FormHelperText className='text-danger'>{errors.roleId && errors.roleId.message}</FormHelperText> */}
                    </div>

                    <div className="col-lg-12 col-12">
                      <div className="form-group mt-2 mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Name <span className="text-blue"></span>
                        </FormLabel>
                        <Controller
                          name="name"
                          control={control}
                          defaultValue=""
                          rules={{ required: `Name is required` }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Name"
                              fullWidth
                              autoFocus
                              type="text"
                              {...field}
                              inputProps={{ disabled: !disable }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.name && errors.name.message}
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Email <span className="text-blue"></span>
                        </FormLabel>
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `Email is required`,
                            validate: validateEmail,
                          }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Email Id"
                              fullWidth
                              type="text"
                              {...field}
                              inputProps={{ disabled: !disable }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.email && errors.email.message}
                        </FormHelperText>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="form-group mb-3">
                        <FormLabel shrink="true" className="input_Lable">
                          Phone Number <span className="text-blue"></span>
                        </FormLabel>
                        <Controller
                          name="phone"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `Phone Number is required`,
                            validate: validatePhoneNumber,
                          }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Enter Phone Number"
                              fullWidth
                              type="number"
                              {...field}
                              inputProps={{ disabled: !disable }}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 10);
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.phone && errors.phone.message}
                        </FormHelperText>
                      </div>
                    </div>

                    {checkMenu === "post" && (
                      <div
                        className={
                          getValues("entityType") === "Others"
                            ? "col-lg-6 col-12"
                            : "col-12"
                        }
                      >
                        <div className="form-group mb-3">
                          <FormLabel shrink="true" className="input_Lable">
                            Entity Type <span className="text-blue"></span>
                          </FormLabel>
                          <Controller
                            name="entityType"
                            control={control}
                            defaultValue=""
                            rules={{ required: `Entity Type is required` }}
                            render={({ field }) => (
                              <Autocomplete
                                disableClearable
                                disablePortal
                                {...field}
                                ListboxProps={{
                                  style: { textAlign: "left" },
                                }}
                                options={entityTypeData}
                                inputProps={{ disabled: !disable }}
                                onChange={(e, selectedOptions) =>
                                  handleSelectEntityType(selectedOptions)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select Entity Type"
                                  />
                                )}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.entityType && errors.entityType.message}
                          </FormHelperText>
                        </div>
                      </div>
                    )}
                    {getValues("entityType") === "Others" && (
                      <div
                        className={
                          getValues("entityType") === "Others"
                            ? "col-lg-6 col-12"
                            : "col-12"
                        }
                      >
                        <div className="form-group mb-3">
                          <FormLabel shrink="true" className="input_Lable">
                            Other Entity Type{" "}
                            <span className="text-blue"></span>
                          </FormLabel>
                          <Controller
                            name="entityTypeOther"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Other Entity Type is required`,
                            }}
                            render={({ field }) => (
                              <TextField
                                placeholder="Enter Other Entity Type"
                                fullWidth
                                type="text"
                                {...field}
                                inputProps={{ disabled: !disable }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.entityTypeOther &&
                              errors.entityTypeOther.message}
                          </FormHelperText>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-12 col-12">
                      <div className="d-flex align-items-center">
                        <Controller
                          name="acceptTerms"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Requried" }}
                          render={({ field }) => (
                            <FormControlLabel
                              {...field}
                              label=""
                              control={
                                <Checkbox
                                  value={field.value}
                                  checked={field.value}
                                />
                              }
                            />
                          )}
                        />
                        <p className="signuproute_p">
                          By registering your details you agree to StartupTN{" "}
                          <Link to={"/privacy-policy"}>Privacy Notice</Link>
                        </p>
                      </div>
                      <FormHelperText className="text-danger">
                        {errors.acceptTerms && errors.acceptTerms.message}
                      </FormHelperText>
                    </div>
                    {/* <div className='col-lg-12 col-12'>
                      <div className='d-flex justify-content-around'>
                        <div className={getValues('roleId') === 3 ? 'tab_signup act_signup' : 'tab_signup'} onClick={() => handleRoleId(3)}>
                          {
                            getValues('roleId') === 3 && (<DoneAllIcon />)
                          }
                          Corporate
                        </div>
                        <div className={getValues('roleId') === 5 ? 'tab_signup act_signup' : 'tab_signup'} onClick={() => handleRoleId(5)}>
                          {
                            getValues('roleId') === 5 && (<DoneAllIcon />)
                          } Government
                        </div>
                        <div className={getValues('roleId') === 7 ? 'tab_signup act_signup' : 'tab_signup'} onClick={() => handleRoleId(7)}>
                          {
                            getValues('roleId') === 7 && (<DoneAllIcon />)
                          } Researcher
                        </div>
                        <div className={getValues('roleId') === 4 ? 'tab_signup act_signup' : 'tab_signup'} onClick={() => handleRoleId(4)}>
                          {
                            getValues('roleId') === 4 && (<DoneAllIcon />)
                          } Solution Provider
                        </div>
                      </div>
                      <div className='d-none'>
                        <div className="form-group mb-3">
                          <Controller name="roleId" control={control} defaultValue="" rules={{ required: 'Role is required' }}
                            render={({ field }) =>
                              <TextField
                                {...field}
                              />} />
                        </div>
                      </div>
                      <FormHelperText className='text-danger'>{errors.roleId && errors.roleId.message}</FormHelperText>
                    </div> */}


                    <div className="col-lg-12 col-12 text-center mt-3">
                      <div className="d-flex justify-content-center mb-3">
                        <ReCAPTCHA
                          sitekey={reCAPTCHA}
                          onChange={(val) => setCapVal(val)}
                        />
                      </div>
                      <LoadingButton
                        loadingPosition="start"
                        type="submit"
                        loading={loading}
                        disabled={!capVal}
                        className="b-blue-btn"
                      >
                        <span>Sign up</span>
                      </LoadingButton>
                    </div>
                  </div>
                  <div className="text-center my-4">
                    <p className="signuproute_p">
                      Already have an account? <Link to="/sign-in">Log in</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 p-0">
            <div className="box_login">
              <img src="/images/login_img.png" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <Modal open={open}>
        <Box sx={style}>
          <div className="success_msg text-center">
            <h3>Registered !</h3>
            <p>
              An email has been sent to{" "}
              <span className="c-origin f-weight-500">
                {getValues("email")}
              </span>{" "}
              with credentials. kindly use the same to login.
            </p>
            <div className="">
              <button className="ani-btn" onClick={handleClose}>
                <span>Ok</span>
                <div className="liquid"></div>
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Signuppage;
